import { Policy } from "@interfaces/policy";
import { CanEditDirectDebitDetails } from "../interfaces/can-edit-direct-debit-details.interface";
import { DirectDebitDetails } from "../interfaces/direct-debit-details.interface";
import { PolicyCanChangeCover } from "../interfaces/policy-can-change-cover.interface";
import { PolicyExcess } from "../interfaces/policy-excess";
import { UpcomingPayments } from "../interfaces/upcoming-payments.interface";
import { StaticDocument } from "@interfaces/static-document";
import { Exclusion } from "@interfaces/exclusion";
import { Claim } from "@interfaces/claim";
import { CoverItem } from "@interfaces/cover-item";
import { ExcessEditState, PetDetailsEditState } from "../services/policy-methods.service";
import { 
  UploadedDocuments,
} from "@features/supporting-documents/interfaces/uploaded-document.interface";
import { 
  PaginatedSearchComponentParams,
} from "@shared/ui/base-components/paginated-search.component";
import { PolicyCommunication } from "../interfaces/policy-communication";
import { CancellationDetails } from "../interfaces/cancellation-details.interface";
import { PaymentDayEditState } from "../enums/payment-day-edit-state.enum";
import { PremiumRecalculation } from "../interfaces/policy-premium-recalculation.interface";
import { HorseColour } from "../interfaces/horse-colour.interface";

export const policyStateName = 'Policy';

export interface PolicyState {
  policy: Policy;
  previousPolicy: Policy;
  isInRenewalWindow: boolean;
  directDebitDetails: DirectDebitDetails;
  canEditDirectDebitDetails: CanEditDirectDebitDetails;
  shouldChangeDirectDebitDetails: boolean;
  upcomingPayments: UpcomingPayments[];
  uploadedDocuments: UploadedDocuments[];
  uploadedDocumentsPageParams: PaginatedSearchComponentParams;
  canChangeCover: PolicyCanChangeCover;
  excess: PolicyExcess;
  excessEditState: ExcessEditState;
  staticDocuments: StaticDocument[];
  exclusions: Exclusion[];
  editableFields: string[];
  claims: Claim[];
  coverItems: CoverItem[];
  petDetailsEditState: PetDetailsEditState;
  paymentDayEditState: PaymentDayEditState;
  paymentDayPremiumRecalculation: PremiumRecalculation;
  cancellation: PetDetailsEditState;
  policyDocuments: PolicyCommunication[];
  cancellationDetails: CancellationDetails;
  horseColours: HorseColour[];
}
