import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';
import { CoverItem } from '@interfaces/cover-item';
import { Exclusion } from '@interfaces/exclusion';
import { PaginatedQuery } from '@interfaces/paginated-query';
import { PaginatedResults } from '@interfaces/paginated-results';
import { Pet } from '@interfaces/pet';
import { Policy } from '@interfaces/policy';
import { PolicyCondition } from '@interfaces/policy-condition';
import { PolicyHolder } from '@interfaces/policyholder';
import { PaginatedPolicySummary } from '@interfaces/paginated-policy-summary';
import { BankValidationResponse } from '../interfaces/bank-validation-response.interface';
import { FeatureFlags } from '@shared/enums/feature-flags.enum';
import { FeatureFlagService } from '@shared/feature-flags/services/feature-flag.service';
import { iif, Observable, of } from 'rxjs';
import { map, shareReplay, mergeMap } from 'rxjs/operators';
import { DirectDebitDetails } from '../interfaces/direct-debit-details.interface';
import { PolicyExcess } from '../interfaces/policy-excess';
import { policyPatchResponse } from '../interfaces/policy-patch-response.interface';
import { PolicyPaymentRequestDto } from '../interfaces/policy-payment-request.interface';
import { PetNameUpdateRequest } from '../interfaces/policy-pet-name-request.interface';
import { TransactionRegistrationResult } from 
  '../interfaces/transaction-registration-result.interface';
import { PolicyMethods } from './policy-methods.service';
import { ProcessRefundDto } from '../interfaces/policy-process-refund.interface';
import { CanEditDirectDebitDetails } from '../interfaces/can-edit-direct-debit-details.interface';
import { UpcomingPayments } from '../interfaces/upcoming-payments.interface';
import { PolicyCanChangeCover } from '../interfaces/policy-can-change-cover.interface';
import { PolicyPatch } from '../interfaces/policy-patch.interface';
import { ShowEndOfBasicPolicyBanner } from 
  '../interfaces/show-end-of-basic-policy-banner.interface';
import { ContactFormat } from '@features/communication-preferences/enums/contact-format.enum';
import { RenewalPatch } from '../interfaces/renewal-patch.interface';
import { RenewalChangeSummary } from '../interfaces/renewal-change-summary.interface';
import { CancellationStatus } from '../interfaces/cancellation-status.interface';
import { BankValidationRequest } from '../interfaces/bank-validation-request.interface';
import { Renewal } from '../interfaces/renewal.interface';
import { PetBreedRequest } from '../interfaces/pet-breed-request.interface';
import { PetGenderRequest } from '../interfaces/pet-gender-request.interface';
import { PetDOBRequest } from '../interfaces/pet-dob-request.interface';
import { PetMicrochipRequest } from '../interfaces/pet-microchip-request.interface';
import { PetMicrochipResponse } from '../interfaces/pet-microchip-response.interface';
import { PetBreedResponse } from '../interfaces/pet-breed-response.interface';
import { PetGenderResponse } from '../interfaces/pet-gender-response.interface';
import { PetDOBResponse } from '../interfaces/pet-dob-response.interface';
import { DiscountResponse } from '../interfaces/discount-response.interface';
import { HorseColour } from '../interfaces/horse-colour.interface';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  private httpOptions = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    }),
  };
  private policiesBaseURL = `${this.buildSettings.POLICY_API}/v1`;
  private masterDataAPIBaseURL = `${this.buildSettings.MASTERDATA_API}/v1`;
  readonly directDebitFeatureFlag$ = this.featureFlagService.isFeatureEnabled(
    FeatureFlags.DirectDebitAmendment,
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  constructor(
    private http: HttpClient,
    private policyMethods: PolicyMethods,
    private featureFlagService: FeatureFlagService,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) { }
  

  getPolicy(policyUniqueRef: string): Observable<Policy> {
    return this.http.get<Policy>(
      `${this.buildSettings.API}/Policy/${policyUniqueRef}`,
    );
  }

  getPolicies(): Observable<PaginatedPolicySummary> {
    return this.http.get<PaginatedPolicySummary>(`${this.buildSettings.API}/Policy`);
  }

  getPagedPolicies(
    top: number,
    skip: number,
    active: boolean,
    search: string,
    showMostRecentOnly: boolean = undefined,
  ): Observable<PaginatedPolicySummary> {
    const params: {
      top: string;
      skip: string;
      active: string;
      search: string;
      showMostRecentOnly: boolean;
    } = {} as any;

    if (top !== undefined) {
      params.top = top.toString();
    }

    if (skip !== undefined) {
      params.skip = skip.toString();
    }

    if (active !== undefined) {
      params.active = active.toString();
    }

    if (search !== undefined) {
      params.search = search.toString();
    }

    if (showMostRecentOnly !== undefined) {
      params.showMostRecentOnly = showMostRecentOnly;
    }

    return this.http.get<PaginatedPolicySummary>(`${this.buildSettings.API}/Policy`, {
      params,
    });
  }

  getAccount(): Observable<PolicyHolder> {
    return this.http
      .get<PaginatedPolicySummary>(`${this.buildSettings.API}/Policy?top=1&skip=0`)
      .pipe(
        map((policySummary) => {
          if (policySummary.resultsCount > 0) {
            return policySummary.policyHolder;
          } else {
            return null;
          }
        }),
      );
  }

  getExclusions(
    query?: GetPolicyExclusionsQuery,
  ): Observable<PaginatedResults<Exclusion>> {
    return this.http.get<PaginatedResults<Exclusion>>(
      `${this.buildSettings.API}/Policy/${query.policyUniqueRef}/Exclusions`,
      {
        params: {
          ...(query as any),
        },
      },
    );
  }

  getCoverSummary(policyUniqueRef: string): Observable<CoverItem[]> {
    return this.http.get<CoverItem[]>(
      `${this.policiesBaseURL}/${policyUniqueRef}/CoverSummary`,
    );
  }

  getPolicyDistinctConditions(
    policyUniqueRef: string,
  ): Observable<PolicyCondition[]> {
    return this.http.get<PolicyCondition[]>(
      `${this.buildSettings.API}/Policy/${policyUniqueRef}/DistinctConditions/`,
    );
  }

  getPets(
    includeInactivePolicies: boolean,
    includeRiderOnlyPolicies: boolean,
  ): Observable<Pet[]> {
    return this.http.get<Pet[]>(
      `${this.buildSettings.API}/Policy/GetPets?IncludeInactivePolicies=${includeInactivePolicies}&IncludeRiderOnlyPolicies=${includeRiderOnlyPolicies}`,
    );
  }

  updateContactNumbers(
    mobile: string,
    phone: string,
    eveningPhone: string,
  ): Observable<PolicyHolder> {
    const params: {
      mobile: string;
      phone: string;
      eveningPhone: string;
    } = {} as any;
    if (mobile !== undefined && mobile !== "") {
      params.mobile = mobile.toString();
    }

    if (phone !== undefined && phone !== "") {
      params.phone = phone.toString();
    }

    if (eveningPhone !== undefined && eveningPhone !== "") {
      params.eveningPhone = eveningPhone.toString();
    }
    
    return this.http.post<PolicyHolder>(
      `${this.buildSettings.API}/Client/UpdatePhone`,
      params,
      {
        ...this.httpOptions,
      },
    );
  }

  updateCommunicationPreferences(
    phone: boolean,
    sms: boolean,
    post: boolean,
    email: boolean,
    documentsEmail: boolean,
    contactFormat: ContactFormat,
  ): Observable<string> {
    return this.http.post<string>(
      // eslint-disable-next-line max-len
      `${this.buildSettings.API}/Client/UpdateCommunicationPreferences?marketPhone=${phone}&marketSMS=${sms}&marketPost=${post}&marketEmail=${email}&documentEmail=${documentsEmail}&contactFormatId=${contactFormat}`,
      '',
      this.httpOptions,
    );
  }

  getExcessCoverText(policy: Policy): string {
    if(policy.pet.type && policy.productVersion){
      return policy.productVersion.startsWith("V3") && this.policyMethods.isPet(policy) ? 
        'AUTH.POLICY.TABLE.EXCESSTTIP.V3' : 
        'AUTH.POLICY.TABLE.EXCESSTTIP.' + policy.pet.type.toUpperCase().replace(/\s/g, '');
    }
    else{
      return '';
    }
  }

  getCoverLevelText(policy: Policy): string {
    return policy.coverLevel
      ? 'AUTH.POLICY.TABLE.COVERTIP.' +
          policy.coverLevel.toUpperCase().replace(/\s/g, '') +
          (this.policyMethods.isHorse(policy) ? 'HORSE' : '')
      : '';
  }

  getCoPaymentTipText(policy: Policy): string {
    return policy.pet.type
      ? 'AUTH.POLICY.TABLE.COPAYMENTTTIP.' +
          policy.pet.type.toUpperCase().replace(/\s/g, '')
      : '';
  }

  getCoverIconName(policy: Policy): string {
    return policy.coverCategory
      ? policy.coverCategory.replace(' ', '').toLowerCase()
      : '';
  }

  getPolicyPetName(policy: Policy): string {
    return this.policyMethods.isPetOrHorse(policy)
      ? policy.pet.petName
      : policy.pet.riderFirstName;
  }

  getPolicyHeader(policy: Policy, isPreviousPolicy: boolean): string {
    return `${this.getPolicyPetName(policy)}'s ${
      isPreviousPolicy ? 'past' : 'current'
    } policy details`;
  }

  updateMicrochipNo(
    policyUniqueRef: string,
    petMicrochipRequest: PetMicrochipRequest,
  ): Observable<PetMicrochipResponse> {
    return this.http.patch<PetMicrochipResponse>(
      `${this.policiesBaseURL}/${policyUniqueRef}/pet/microchip-number`,
      petMicrochipRequest,
      {
        ...this.httpOptions,
      },
    );
  }

  updatePetDOB
  (
    policyUniqueRef: string,
    petDOBRequest: PetDOBRequest,
  ): Observable<PetDOBResponse> {
    return this.http.patch<PetDOBResponse>(
      `${this.policiesBaseURL}/${policyUniqueRef}/pet/birth-date`,
      petDOBRequest,      
      {
        ...this.httpOptions,
      },
    );
  }

  updateExcess
  (
    policyUniqueRef: string, 
    oldExcess: number, 
    newExcess: number,
  ): Observable<policyPatchResponse> {
    const params: {
      oldExcess: string;
      newExcess: string;      
    } = {} as any;
    params.oldExcess = oldExcess.toString();
    params.newExcess = newExcess.toString();
    return this.http.patch<policyPatchResponse>(
      `${this.policiesBaseURL}/${policyUniqueRef}/Excess`,
      '',      
      {
        ...this.httpOptions,
        params,
      },
    );
  }

  updateGender
  (
    policyUniqueRef: string,
    petGenderRequest: PetGenderRequest,
  ): Observable<PetGenderResponse> {
    return this.http.patch<PetGenderResponse>(
      `${this.policiesBaseURL}/${policyUniqueRef}/pet/gender`,
      petGenderRequest,      
      {
        ...this.httpOptions,
      },
    );
  }

  updatePetName
  (
    policyUniqueRef: string, 
    petNameUpdateRequest: PetNameUpdateRequest,
  ): Observable<string> {
    return this.http.patch<string>(
      `${this.policiesBaseURL}/${policyUniqueRef}/pet/name`,
      {
        newName: petNameUpdateRequest.newName,
      },      
      {
        ...this.httpOptions,
      },
    );
  }

  updateBreed
  (
    policyUniqueRef: string, 
    petBreedRequest: PetBreedRequest,
  ): Observable<PetBreedResponse> {
    return this.http.patch<PetBreedResponse>(
      `${this.policiesBaseURL}/${policyUniqueRef}/pet/breed`,
      petBreedRequest,      
      {
        ...this.httpOptions,
      },
    );
  }

  getChangeAllowed(policyUniqueRef: string, factor: string): Observable<boolean> {
    const params: {
      factor: string
    } = {} as any;
    params.factor = factor;
    return this.http.get<boolean>(
      `${this.policiesBaseURL}/${policyUniqueRef}/ChangeAllowed?factor=${factor}`);

  }

  auditCancelledChange
  (
    policyUniqueRef: string,
    factor: string, 
    oldValue: string, 
    newValue: string,
  ): Observable<number> {
    const change: {
      PolicyNoLong: string;
      Factor: string;      
      OldValue: string;
      NewValue: string;
    } = {} as any;

    change.PolicyNoLong = policyUniqueRef;
    change.Factor = factor;
    change.OldValue = oldValue;
    change.NewValue = newValue;
    const body  = [change];    
    return this.http.post<number>(
      `${this.policiesBaseURL}/AuditCancelledChanges`,
      body,      
      {
        ...this.httpOptions,
      },
    );
  }

  getEditableFields(policyUniqueRef: string): Observable<string[]> {
    return this.http.get<string[]>(     
      `${this.policiesBaseURL}/${policyUniqueRef}/EditableFields`);
  }
  
  getExcess(policyUniqueRef: string): Observable<PolicyExcess> {
    return this.http.get<PolicyExcess>(`${this.policiesBaseURL}/${policyUniqueRef}/Excess`);
  }
  
  hasOpenClaims(policyUniqueRef: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.policiesBaseURL}/${policyUniqueRef}/HasOpenClaim`);
  }

  policyPayment(policyPaymentRequestDto: PolicyPaymentRequestDto) {
    return this.http.post<TransactionRegistrationResult>
    (
      `${this.policiesBaseURL}/PolicyPayment`, 
      policyPaymentRequestDto,
    );
  }

  shouldChangeDirectDebitDetails(policyNoLong: string) {
    return this.directDebitFeatureFlag$.pipe(
      mergeMap((isFeatureEnabled) =>
        iif(
          () => isFeatureEnabled,
          this.http.get<boolean>
          (`${this.policiesBaseURL}/${policyNoLong}/ShouldChangeDirectDebitDetails`),
          of(false),
        ),
      ),
    );
  }

  getDirectDebitDetails(policyNoLong: string) {
    return this.directDebitFeatureFlag$.pipe(
      mergeMap((isFeatureEnabled) =>
        iif(
          () => isFeatureEnabled,
          this.http.get<DirectDebitDetails>
          (`${this.policiesBaseURL}/${policyNoLong}/direct-debit`),
          of(undefined),
        ),
      ),
    );
  }

  canEditDirectDebitDetails(policyNoLong: string) {
    return this.directDebitFeatureFlag$.pipe(
      mergeMap((isFeatureEnabled) =>
        iif(
          () => isFeatureEnabled,
          this.http.get<CanEditDirectDebitDetails>
          (`${this.policiesBaseURL}/${policyNoLong}/canEditDirectDebitDetails`),
          of({ canEditDirectDebitDetails: false, canReinstateDirectDebit: false }),
        ),
      ),
    );
  }

  updateDirectDebitDetails
  (
    policyUniqueRef: string, 
    directDetbitDetails: DirectDebitDetails,
  ): Observable<policyPatchResponse> {
    return this.http.patch<policyPatchResponse>(
      `${this.policiesBaseURL}/${policyUniqueRef}/direct-debit`,
      directDetbitDetails,      
      {
        ...this.httpOptions,
      },
    );
  }

  validateBankAccount
  (
    policyUniqueRef: string, 
    bankValidationRequest: BankValidationRequest,
  ): Observable<BankValidationResponse> {
    return this.http.post<BankValidationResponse>(
      `${this.policiesBaseURL}/${policyUniqueRef}/ValidateBankDetails`,
      bankValidationRequest,
    );
  }

  creditCardRefund(processRefundDto: ProcessRefundDto) {
    return this.http.post<boolean>(`${this.policiesBaseURL}/CreditCardRefund`, processRefundDto);
  }

  debitOrderRefund(processRefundDto: ProcessRefundDto): Observable<number> {
    return this.http.post<number>(`${this.policiesBaseURL}/DebitOrderRefund`, processRefundDto);
  }

  reinstateDirectDebit(policyUniqueRef: string) {
    return this.http.patch<policyPatchResponse>
    (`${this.policiesBaseURL}/${policyUniqueRef}/ReinstateDirectDebit`, {});
  }

  upcomingPayment(policyUniqueRef: string): Observable<UpcomingPayments[]> {
    return this.http.get<UpcomingPayments[]>
    (`${this.policiesBaseURL}/${policyUniqueRef}/GetUpcomingPayments`);
  }

  showBasicBanner(policyUniqueRef: string): Observable<ShowEndOfBasicPolicyBanner> {
    return this.http.get<ShowEndOfBasicPolicyBanner>
    (`${this.policiesBaseURL}/${policyUniqueRef}/ShowEndOfBasicPolicyBanner`);
  }

  canChangeCover(policyUniqueRef: string): Observable<PolicyCanChangeCover> {
    return this.http.get<PolicyCanChangeCover>
    (`${this.policiesBaseURL}/change-cover/${policyUniqueRef}/restrictions`);
  }

  patchPolicy
  (
    policyUniqueRef: string, 
    policyPatch: PolicyPatch,
  ): Observable<policyPatchResponse[]> {
    return this.http.patch<policyPatchResponse[]>
    (`${this.policiesBaseURL}/${policyUniqueRef}`, policyPatch);
  }

  patchPolicyRenewal(
    policyUniqueRef: string,
    renewalPatch: RenewalPatch,
  ) {
    return this.http.patch(
      `${this.policiesBaseURL}/${policyUniqueRef}/renewal`,
      renewalPatch,
    );
  }

  getRenewalSummary(
    policyUniqueRef: string,
  ): Observable<RenewalChangeSummary> {
    return this.http.get<RenewalChangeSummary>(
      `${this.policiesBaseURL}/${policyUniqueRef}/renewal/change-summary`,
    );
  }

  updateHorseColour(policyUniqueRef: string, horseColour: string): Observable<string> {
    return this.http.patch<string>(
      `${this.policiesBaseURL}/${policyUniqueRef}/HorseColour?colour=${horseColour}`,    
      {
        ...this.httpOptions,
      },
    );
  }
  getCancellationStatus(policyUniqueRef: string): Observable<CancellationStatus> {
    return this.http.get<CancellationStatus>
    (`${this.policiesBaseURL}/${policyUniqueRef}/cancel`);
  }

  getRenewal(policyNoLong: string): Observable<Renewal> {
    return this.http.get<Renewal>(
      `${this.policiesBaseURL}/${policyNoLong}/renewal`,
    );
  }

  getDiscount(policyNoLong: string): Observable<DiscountResponse> {
    return this.http.get<DiscountResponse>(
      `${this.policiesBaseURL}/${policyNoLong}/renewal/discount`,
    );
  };

  applyDiscount(policyNoLong: string): Observable<policyPatchResponse> {
    return this.http.post<policyPatchResponse>(
      `${this.policiesBaseURL}/${policyNoLong}/renewal/discount`,
      null,
    );
  };

  getHorseColours(): Observable<HorseColour[]>{
    return this.http.get<HorseColour[]>(
      `${this.masterDataAPIBaseURL}/breed/horse/colour`,
    );
  }
}

export interface GetPolicyExclusionsQuery extends PaginatedQuery {
  policyUniqueRef?: string;
}
